import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { MEDIA_QUERY_MEDIUM } from "../styles/variables";
import React from "react";
import SEO from "../components/SEO";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 2rem 3rem;
  line-height: 1.5;

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

const Headline = styled.h1`
  color: var(--themeColour);
  margin-top: 3rem;
  font-weight: bold;
  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    margin-top: 4rem;
  }
`;


const FourOhFour = ({ data }) => (
  <>
    <SEO title="Page not found - Error 404" />
    <Img fluid={data.settings.image.asset.fluid} />
    <Wrapper>
      <Headline>Oops!</Headline>
      <p>The page you requested cannot be found.</p>
      <p>Try starting from the <Link to="/">homepage</Link>.</p>
    </Wrapper>
  </>
);

export default FourOhFour;

export const query = graphql`
  query {
    settings: sanitySettings(_id: {eq: "SiteSettings"}) {
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
